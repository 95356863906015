/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass form context to the rest of the app.
 */
import FormByIdContext from '../../../contexts/data/FormContext';

/*
 * Used to load collaborating groups
 */
import useFormDataById from '../../../hooks/data/useFormDataById';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import LookupsContext from '../../../contexts/utility/LookupsContext';
import FormDefinitionContext from '../../../contexts/configuration/FormDefinitionContext';
import useFormDefinitionByCode from '../../../hooks/configuration/useFormDefinitionByCode';
import useFormDefinitionById from '../../../hooks/configuration/useFormDefinitionById';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormByIdResolverProps {
    formDefinitionCode: string;
    id: number | null,
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IFormMapping>;
    formDataMapping?: Record<string, Dtos.IForm>;
    formMetadataMapping?: Record<string, Record<string, any>>;
}

export interface IFormMapping {
    components: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormByIdResolver: React.FunctionComponent<IFormByIdResolverProps> = ({
    formDefinitionCode,
    id,
    resolveBeforeLoad,
    formMapping,
    formDataMapping,
    formMetadataMapping,
    children
}) => {
    const createData = formDataMapping?.[formDefinitionCode];
    const createMetadata = formMetadataMapping?.[formDefinitionCode];

    const formContext = useFormDataById(formDefinitionCode, id, createData, createMetadata);

    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id);

    const formDefCode = formDefinitionContext?.data?.code ?? '';

    const conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const notLoading =
        !formDefinitionContext?.loading &&
        !formContext?.loading &&
        !lookupsContext?.loading;

    return (
        <FormDefinitionContext.Provider value={formDefinitionContext}>
            <FormByIdContext.Provider value={formContext}>
                <LookupsContext.Provider value={lookupsContext}>
                    {
                        !resolveBeforeLoad || notLoading ?
                            conditionalContexts :
                            <RouteLoading />
                    }
                </LookupsContext.Provider>
            </FormByIdContext.Provider>
        </FormDefinitionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormByIdResolver;