/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Paper } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFormState } from '@ngt/forms-core';

import * as Dtos from '../../../api/dtos';

import CollapsibleTable from '../../table/CollapsibleTable';

import Table from '../../table/Table';
import Markdown from '../../utility/Markdown';
import { GridColDef } from '@material-ui/data-grid';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormErrors component.
 */
export interface IFormErrorsProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: GridColDef[] = [
    {
        field: 'propertyName',
        headerName: 'Property Path',
        flex: 1
    },
    {
        field: 'errorCount',
        headerName: 'Error Count',
        valueGetter: rowData => rowData.row?.errors?.length ?? 0,
        flex: 1
    }
]

const Container = (props: any) => {
    return <Paper {...props} elevation={0} />;
}

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormErrors: React.FunctionComponent<IFormErrorsProps> = ({
    children
}) => {
    const { errors } = useFormState<any, Dtos.IValidationError>({ errors: true });

    const data = React.useMemo(() => {
        if (!errors) {
            return [];
        }

        return Object.keys(errors).filter(key => errors[key] && errors[key].length > 0).map((key, i) => ({ id: i,  propertyName: key, errors: errors[key] }))
    }, [errors])

    return (
        <CollapsibleTable
            title="Field Errors"
            entityName="errors"
            loading={false}
            rows={data}
            columns={columns}
            startCollapsed
            autoHeight
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormErrors;