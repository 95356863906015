/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the reducer registry class used to control adding reducers
 * and logic to the redux store at run time.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { get, set, cloneDeep } from 'lodash-es'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get object property paths.
 */
import getDeepKeys from './utilities/getDeepKeys';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This enum specifies when the form should be validated.
 */
export enum ValidateOn {
    /** When the form is submitted. */
    onSubmit,
    /** When focus is lost from a field. */
    onBlur,
    /** When a field is changed. */
    onChange
}

/** The function interface used for the form validation function. */
export interface IFormValidate<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, formActions: IFormActions<TValues, TError>): Promise<Record<string, TError[]>>
}

/** The function interface used for the form allow submit function. */
export interface IFormAllowSubmit<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, formActions: IFormActions<TValues, TError>): Promise<boolean>;
}

/** The function interface used for the form submit function. */
export interface IFormSubmit<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, formActions: IFormActions<TValues, TError>): Promise<void | Record<string, TError[]>>
}

/** The function interface used for the form submit failed function. */
export interface IFormSubmitFailed<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, formActions: IFormActions<TValues, TError>): Promise<void | Record<string, TError[]>>
}

/** The function interface used for the form validation failed (error thrown) function. */
export interface IFormSubmitValidationFailed<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, formActions: IFormActions<TValues, TError>, validationError: boolean): Promise<void | Record<string, TError[]>>
}

/** The interface used for defining the configuration options of the form. */
export interface IFormManagerOptions<TValues extends object = any, TError = any> {
    /** The initial values that the form should use. */
    initialValues?: TValues | null;
    /** How the form should validate the fields. */
    onValidate?: IFormValidate<TValues, TError> | null;
    /** When the form should allow submission. */
    allowSubmit?: IFormAllowSubmit<TValues, TError> | null;
    /** What the form should do on submission. */
    onSubmit?: IFormSubmit<TValues, TError> | null;
    /** What the form should do if the validation fails. */
    onSubmitValidationFailed?: IFormSubmitValidationFailed<TValues, TError> | null;
    /** What the form should do if the submission fails. */
    onSubmitFailed?: IFormSubmitFailed<TValues, TError> | null;
    /** When the form should validate. */
    validateOn?: ValidateOn;
    /** 
     *  If the form should delay clearing errors on a reset and instead revalidate 
     *  the form to correct the error state of the form.
     *  
     *  If using asynchronous validation with errors not hidden by touched state 
     *  this could fix errors that flicker when reseting the form.
     */
    validateOnReset?: boolean;
}

/** The interface used for the form state. */
export interface IFormState<TValues extends object = any, TError = any> {
    /** The current value of the form. */
    values: TValues;
    /** The registered field property paths. */
    fields: string[];
    /** The touched fields */
    touched: Record<string, boolean>;
    /** The dirty (changed) fields */
    dirty: Record<string, boolean>;
    /** The initial value of the form. */
    initialValues: TValues;
    /** The currently focused fields. */
    focused: Record<string, boolean>;
    /** The current form errors. */
    errors: Record<string, TError[]>;
    /** If the form is currently validating. */
    validating: boolean;
    /** If the form is currently submitting. */
    submitting: boolean;
}

export interface IFormActions<TValues extends object = any, TError = any> {
    getValues: FormManager<TValues, TError>['getFormValues'];
    getTouched: FormManager<TValues, TError>['getFormTouched'];
    getDirty: FormManager<TValues, TError>['getFormDirty'];
    getFocused: FormManager<TValues, TError>['getFormFocused'];
    getErrors: FormManager<TValues, TError>['getFormErrors'];
    getInitialValues: FormManager<TValues, TError>['getFormInitialValues'];
    getSubmitting: FormManager<TValues, TError>['getFormSubmitting'];
    getValidating: FormManager<TValues, TError>['getFormValidating'];
    setValues: FormManager<TValues, TError>['setFormValues'];
    setTouched: FormManager<TValues, TError>['setFormTouched'];
    setDirty: FormManager<TValues, TError>['setFormDirty'];
    setFocused: FormManager<TValues, TError>['setFormFocused'];
    setErrors: FormManager<TValues, TError>['setFormErrors'];
    setValidating: FormManager<TValues, TError>['setFormValidating'];
    setSubmitting: FormManager<TValues, TError>['setFormSubmitting'];
    getFieldValue: FormManager<TValues, TError>['getFieldValue'];
    getFieldTouched: FormManager<TValues, TError>['getFieldTouched'];
    getFieldDirty: FormManager<TValues, TError>['getFieldDirty'];
    getFieldFocused: FormManager<TValues, TError>['getFieldFocused'];
    getFieldErrors: FormManager<TValues, TError>['getFieldErrors'];
    getFieldInitialValue: FormManager<TValues, TError>['getFieldInitialValue'];
    setFieldValue: FormManager<TValues, TError>['setFieldValue'];
    setFieldTouched: FormManager<TValues, TError>['setFieldTouched'];
    setFieldDirty: FormManager<TValues, TError>['setFieldDirty'];
    setFieldFocused: FormManager<TValues, TError>['setFieldFocused'];
    setFieldErrors: FormManager<TValues, TError>['setFieldErrors'];
    reset: FormManager<TValues, TError>['reset'];
    submit: FormManager<TValues, TError>['submit'];
    validate: FormManager<TValues, TError>['validate'];
    subscribe: FormManager<TValues, TError>['subscribeToForm'];
    subscribeToField: FormManager<TValues, TError>['subscribeToField'];
    registerField: FormManager<TValues, TError>['registerField'];
    unregisterField: FormManager<TValues, TError>['unregisterField'];
    getFields: FormManager<TValues, TError>['getFields'];
    notifyFormChange: FormManager<TValues, TError>['notifyFormChange'];
    notifyFieldChange: FormManager<TValues, TError>['notifyFieldChange'];
}

/** The interface used to describe subscriptions to the form. */
export interface IFormSubscription extends Record<keyof IFormState, boolean> {

}

/** The interface used to describe a listener to the form (a subscription + event to run). */
export interface IFormListener<TValues extends object = any, TError = any> {
    /** When to notifiy the subscription. */
    subscription: IFormSubscription;
    /** What to do when notifying the subscription. */
    subscriber: IFormSubscriber<TValues, TError>;
}

/** The interface used for the field state. */
export interface IFieldState<TValue = any, TError = any> {
    /** The current value of the field. */
    value: TValue | null | undefined;
    /** Has the field been touched (focused + blurred). */
    touched: boolean;
    /** Is the field dirty (changed). */
    dirty: boolean;
    /** The initial value of the field. */
    initialValue: TValue | null | undefined;
    /** Is the field focused. */
    focused: boolean;
    /** The current errors for the field. */
    errors: TError[];
}

/** The interface used to describe subscriptions to a field. */
export interface IFieldSubscription extends Record<keyof IFieldState, boolean> {

}

/** The function interface used to define a subscription event for the form. */
export interface IFormSubscriber<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>): void;
}

/** The function interface used to define a subscription event for a field. */
export interface IFieldSubscriber<TValue = any, TError = any> {
    (fieldState: IFieldState<TValue, TError>): void;
}

/** The interface used to describe a listener to a field (a subscription + event to run). */
export interface IFieldListener<TValue = any, TError = any> {
    /** The property path of the field to subscribe to. */
    path: string;
    /** When to notifiy the subscription. */
    subscription: IFieldSubscription;
    /** What to do when notifying the subscription. */
    subscriber: IFieldSubscriber<TValue, TError>;
}

/** The function interface used to describe an unsubscribe event. */
export interface IUnsubscribe {
    (): void;
}


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

const isDevelopment = process.env.NODE !== "production";

/**
 * This class handles the form state of a form
 */
export class FormManager<TValues extends object = any, TError = any> {

    /** The current value of the form. */
    private values: TValues = {} as TValues;
    /** The initial value of the form. */
    private initialValues: TValues = {} as TValues;
    /** Which fields have been touched (focused + blurred or changed). */
    private touched: Record<string, boolean> = {};
    /** Which fields have been changed */
    private dirty: Record<string, boolean> = {};
    /** Which fields are currently focused. */
    private focused: Record<string, boolean> = {};
    /** Which fields currently have errors. */
    private errors: Record<string, TError[]> = {};
    /** What to do on submission of the form. */
    private onSubmit?: IFormSubmit<TValues, TError> | null = null;
    /** What to do to validate the form. */
    private onValidate?: IFormValidate<TValues, TError> | null = null;
    /** What to do if the validation fails. */
    private onSubmitValidationFailed?: IFormSubmitValidationFailed<TValues, TError> | null = null;
    /** What to do if submission fails */
    private onSubmitFailed?: IFormSubmitFailed<TValues, TError> | null = null;
    /** The collection of listeners registered to the form. */ 
    private formListeners: IFormListener<TValues, TError>[] = [];
    /** The collection of listeners registered to form fields. */
    private fieldListeners: IFieldListener<any, TError>[] = [];
    /** When the form should validate. */
    private validateOn: ValidateOn = ValidateOn.onSubmit;
    /** When the form should allow submission */
    private allowSubmit?: IFormAllowSubmit<TValues, TError> | null = null;
    /** If the form is currently validating. */
    private validating: boolean = false;
    /** If the form is currently submitting. */
    private submitting: boolean = false;
    /** The list of registered fields */
    private fields: string[] = [];
    /**
     *  If the form should delay clearing errors on a reset and instead validate
     *  the form to correct the error state of the form.
     *
     *  If using asynchronous validation with errors not hidden by touched state
     *  this could fix errors that flicker when reseting the form.
     */
    private validateOnReset: boolean = false;

    /**
     * The constructor for the form.
     * @param options The configuration options for the form.
     */
    constructor(options?: IFormManagerOptions) {
        /*
         * Set the configuration options. 
         */
        this.initialValues = cloneDeep(options?.initialValues ?? {} as TValues);
        this.onSubmit = options?.onSubmit;
        this.onSubmitFailed = options?.onSubmitFailed;
        this.onSubmitValidationFailed = options?.onSubmitValidationFailed;
        this.onValidate = options?.onValidate;
        this.allowSubmit = options?.allowSubmit;
        this.validateOn = options?.validateOn ?? ValidateOn.onSubmit;
        this.validateOnReset = options?.validateOnReset ?? false;

        /*
         * Reset the form.
         * 
         * This will run through and initialise the remaining values of the form.
         */
        this.reset(true, true);
    }

    /**
     * Set what the form should do on submission.
     * @param onSubmit What to do on submission.
     */
    public setOnSubmit = (onSubmit?: IFormSubmit<TValues, TError> | null) => {
        this.onSubmit = onSubmit;
    }

    /**
     * Set how the form determines if submission is allowed.
     * @param allowSubmit how to determine if submission is allowed.
     */
    public setAllowSubmit = (allowSubmit?: IFormAllowSubmit<TValues, TError> | null) => {
        this.allowSubmit = allowSubmit;
    }

    /**
     * Set how to validate the form.
     * @param onValidate How to validate the form.
     */
    public setOnValidate = (onValidate?: IFormValidate<TValues, TError> | null) => {
        this.onValidate = onValidate;
    }

    /**
     * Set when to validate the form.
     * @param validateOn When to validate the form.
     */
    public setValidateOn = (validateOn?: ValidateOn | null) => {
        this.validateOn = validateOn ?? ValidateOn?.onSubmit;
    }

    /**
     * Set the initial field values of the form.
     * 
     * This will reset the form.
     * @param initialValues The initial field values.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields whether or not to notify the field listeners of the change (default: true).
     */
    public setInitialValues = (initialValues?: TValues | null, notifyForm?: boolean, notifyFields?: boolean) => {
        /* Update if the provided reference is different to the current reference */
        if (initialValues != this.initialValues) {
            this.initialValues = initialValues ?? {} as TValues;

            /* 
             * Reset the form but do not notify the form or field listeners.
             * 
             * Notification will be handled by setInitialValues directly as it needs to notify
             * of initial value change which the reset function does not do.
             */
            this.reset(false, false);


            /*
             * Notify field listeners (if requested) that a full form reset has occurred.
             */
            if (notifyFields !== false) {
                this.notifyAllFields({
                    dirty: true,
                    errors: true,
                    focused: true,
                    initialValue: true,
                    touched: true,
                    value: true
                });
            }

            /*
             * Notify form listeners (if requested) that a full form reset has occurred.
             */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: true,
                    errors: true,
                    fields: false,
                    focused: true,
                    initialValues: true,
                    touched: true,
                    values: true,
                    submitting: true,
                    validating: true
                });
            }

            /* Return true to signify that initial values was updated. */
            return true;
        }

        /* Return false to signify that initial values was NOT updated. */
        return false;
    }

    /**
     * Reset the form to the initial state.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields whether or not to notify the field listeners of the change (default: true).
     */
    public reset = (notifyForm?: boolean, notifyFields?: boolean) => {

        /* Reset form state to the initial states */
        this.setFormValues(cloneDeep(this.initialValues), false, false, false, false);
        this.setFormTouched({}, false, false);
        this.setFormDirty({}, false, false);
        this.setFormFocused({}, false, false);
        this.setFormSubmitting(false, false);
        this.setFormValidating(false, false);

        /* 
         * Reset errors if not waiting on revalidation 
         * 
         * If errors are displayed all the time (not hidden based on touched, etc)
         * then flickers could occur if the errors are cleared and then revalidation
         * immediately occurs.
         */
        if (!this.validateOnReset) {
            this.setFormErrors({}, false, false);
        }

        /*
         * Notify field listeners (if requested) that a form reset has occurred.
         */
        if (notifyFields) {
            this.notifyAllFields({
                dirty: true,
                errors: false,
                focused: true,
                initialValue: false,
                touched: true,
                value: true
            });
        }

        /*
         * Notify form listeners (if requested) that a form reset has occurred.
         */
        if (notifyForm) {
            this.notifyFormChange({
                dirty: true,
                fields: false,
                errors: false,
                focused: true,
                initialValues: false,
                touched: true,
                values: true,
                submitting: true,
                validating: true
            });
        }

        /*
         * Revalidate form if requested to do so.
         */
        if (this.validateOnReset) {
            this.validate();
        }
    }

    /**
     * Set the form values.
     * @param values The new set of values.
     * @param updateDirty Whether or not to update dirty state to true for changed fields (default: true).
     * @param updateTouched Whether or not to update touched state to true for changed fields (default: true).
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields whether or not to notify the field listeners (changed fields only) of the change (default: true).
     */
    public setFormValues = (values: TValues, updateDirty?: boolean, updateTouched?: boolean, notifyForm?: boolean, notifyFields?: boolean) => {
        /* Update if the provided reference is different to the current reference */
        if (values !== this.values) {
            const processChangedFields = notifyFields !== false || updateDirty !== false || updateTouched !== false;

            /* collect all paths that have new values */
            const updatedPaths = processChangedFields !== false ? this.updateValuePaths(this.values, values ?? {}) : [];

            /* update form values */
            this.values = values ?? {};

            /* update dirty state of changed fields (if requested) */
            if (updateDirty !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.setFieldDirty(updatedPath, true, false, false);
                });
            }

            if (updateTouched !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.setFieldTouched(updatedPath, true, false, false);
                });
            }

            /* process changed fields if required. */
            if (processChangedFields) {
                updatedPaths.forEach(updatedPath => {
                    /* update dirty state of changed field (if requested) */
                    const notifyDirty = updateDirty !== false && this.setFieldDirty(updatedPath, true, false, false);

                    /* update touched state of changed field (if requested) */
                    const notifyTouched = updateTouched !== false && this.setFieldTouched(updatedPath, true, false, false);

                    /*
                     * Notify field listeners of updated field (if requested) of form value change.
                     */
                    if (notifyFields !== false) {
                        this.notifyFieldChange(
                            updatedPath,
                            {
                                dirty: notifyDirty,
                                errors: false,
                                focused: false,
                                initialValue: false,
                                touched: notifyTouched,
                                value: true
                            }
                        );
                    }
                });
            }

            /*
             * Notify form listeners (if requested) of form value change.
             */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: updateDirty !== false,
                    fields: false,
                    errors: false,
                    focused: false,
                    initialValues: false,
                    touched: updateTouched !== false,
                    values: true,
                    submitting: false,
                    validating: false
                });
            }

            /* Validate form if configured to validate on value change. */
            if (this.validateOn === ValidateOn.onChange) {
                this.validate();
            }

            /* Return true to signify that form values were updated. */
            return true;
        }

        /* Return false to signify that form values were NOT updated. */
        return false;
    }

    /**
     *  Get the current value of the form.
     */
    public getFormValues = () => {
        return this.values;
    }

    /**
     * Get the initial value of the form. 
     */
    public getFormInitialValues = () => {
        return this.initialValues;
    }

    /**
     * Register a field on the form.
     * @param path  Property path of the field.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     */
    public registerField = (path: string, notifyForm?: boolean) => {
        /* Only register field if it is not already registered */
        if (!this.fields.includes(path)) {
            /* Add field to registered set */
            this.fields = [...this.fields, path];

            /* Notify form listeners of the change (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: true,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                })
            }

            /* Return true to signify that a field was registered */ 
            return true;
        }

        /* Return false to signify that a field was NOT registered */
        return false;
    }

    /**
     * Unregister a field from the form.
     * @param path  Property path of the field.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     */
    public unregisterField = (path: string, notifyForm?: boolean) => {
        /* Only unregister field if it is currently registered */
        if (this.fields.includes(path)) {
            /* remove field from set of registered fields */
            this.fields = this.fields.filter(f => f !== path);

            /* Notify form listeners of the change (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: true,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                })
            }

            /* Return true to signify that a field was unregistered */
            return true;
        }

        /* Return false to signify that a field was NOT unregistered */
        return false;
    }

    /*
     * Get the property paths of all fields currently registered on the form.
     */
    public getFields = () => {
        return this.fields;
    }

    /*
     * Get whether the form is submitting or not.
     */
    public getFormSubmitting = () => {
        return this.submitting;
    }

    /**
     * Set whether the form is submitting or not.
     * @param submitting Whether the form is submitting or not.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     */
    public setFormSubmitting = (submitting: boolean, notifyForm?: boolean) => {
        /* Only update if value different from the current value */
        if (submitting !== this.submitting) {

            /* Update form submitting state */
            this.submitting = submitting;

            /* Notify form listeners of the change (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: true,
                    validating: false
                });
            }

            /* Return true to signify submitting was updated. */
            return true;
        }

        /* Return false to signify submitting was NOT updated. */
        return false;
    }

    /**
     * Get whether the form is validating or not. 
     */
    public getFormValidating = () => {
        return this.validating;
    }

    /**
     * Set whether the form is validating or not.
     * @param validating Whether the form is validating or not.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     */
    public setFormValidating = (validating: boolean, notifyForm?: boolean) => {
        /* Only update if value different from the current value */
        if (validating !== this.validating) {

            /* Update form validating state */
            this.validating = validating;

            /* Notify form listeners of the change (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: true
                });
            }

            /* Return true to signify validating was updated. */
            return true;
        }

        /* Return false to signify validating was NOT updated. */
        return false;
    }

    /**
     * Set which fields have been touched.
     * @param touched The fields that have been touched.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFormTouched = (touched: Record<string, boolean> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (touched !== this.touched) {
            /* Get collection of field property paths that are effected by this change. */
            const updatedPaths = notifyFields !== false ? this.updatedStatePaths(this.touched, touched ?? {}) : [];

            /* update touched fields */
            this.touched = touched ?? {};

            /* Notify field listeners for affected fields of this change (if requested). */
            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: false,
                            focused: false,
                            initialValue: false,
                            touched: true,
                            value: false
                        }
                    );
                });
            }

            /* Notify form listeners of this change (if requested). */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: true,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* Return true to signify that touched was updated */
            return true;
        }

        /* Return false to signify that touched was NOT updated */
        return false;
    }

    /**
     * Get the current form touched state. 
     */
    public getFormTouched = () => {
        return this.touched;
    }

    /**
     * Set which fields have been made dirty.
     * @param dirty The fields that have been touched.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFormDirty = (dirty: Record<string, boolean> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (dirty !== this.dirty) {
            /* Get collection of field property paths that are effected by this change. */
            const updatedPaths = notifyFields !== false ? this.updatedStatePaths(this.dirty, dirty ?? {}) : [];

            /* update dirty fields */
            this.dirty = dirty ?? {};

            /* Notify field listeners for affected fields of this change (if requested). */
            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: true,
                            errors: false,
                            focused: false,
                            initialValue: false,
                            touched: false,
                            value: false
                        }
                    );
                });
            }

            /* Notify form listeners of this change (if requested). */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: true,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* Return true to signify that dirty was updated */
            return true;
        }

        /* Return false to signify that dirty was NOT updated */
        return false;
    }

    /**
     * Get the current form dirty state.
     */
    public getFormDirty = () => {
        return this.dirty;
    }

    /**
     * Set which fields are focused.
     * @param focused The fields that are focused.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFormFocused = (focused: Record<string, boolean> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (focused !== this.focused) {
            const validateOnBlur = this.validateOn === ValidateOn.onBlur;

            /* Get collection of field property paths that are effected by this change. */
            const updatedPaths = notifyFields !== false || validateOnBlur ? this.updatedStatePaths(this.focused, focused ?? {}) : [];

            let validate = false;

            /* 
             * Set flag to trigger validation if a property path was previously focused and is now no longer focused and
             * validation is configured as on blur. 
             */
            if (validateOnBlur) {
                validate = updatedPaths.some(path => (focused ?? {})[path] !== true && this.focused[path] === true)
            }

            /* update focused fields */
            this.focused = focused ?? {};

            /* Notify field listeners for affected fields of this change (if requested). */
            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: false,
                            focused: true,
                            initialValue: false,
                            touched: false,
                            value: false
                        }
                    );
                });
            }

            /* Notify form listeners of this change (if requested). */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: true,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* validate if flag previously set */
            if (validate) {
                this.validate();
            }

            /* Return true to signify that focused was updated */
            return true;
        }

        /* Return true to signify that focused was NOT updated */
        return false;
    }

    /**
     * Get the current form focused state.
     */
    public getFormFocused = () => {
        return this.focused;
    }

    /**
     * Set fields errors.
     * @param errors The fields and associated errors.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyFields Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFormErrors = (errors: Record<string, TError[]> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (errors !== this.errors) {

            /* Get collection of field property paths that are effected by this change. */
            const updatedPaths = notifyFields !== false ? this.updatedStatePaths(this.errors, errors ?? {}) : [];

            /* update field errors */
            this.errors = errors ?? {};

            /* Notify field listeners for affected fields of this change (if requested). */
            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: true,
                            focused: false,
                            initialValue: false,
                            touched: false,
                            value: false
                        }
                    );
                });
            }

            /* Notify form listeners of this change (if requested). */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: true,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* Return true to signify that field errors were updated */
            return true;
        }

        /* Return false to signify that field errors were NOT updated */
        return false;
    }

    /**
     * Get the current form error state.
     */
    public getFormErrors = () => {
        return this.errors;
    }

    /**
     * Sets the value of a field on the form.
     * @param path The property path of the field.
     * @param value The new value of the field.
     * @param updateDirty Update the fields dirty state (default: true).
     * @param updateTouched Update the fields touched state (default: true).
     * @param updateFocused Update the fields focused state (default: true).
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyField Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFieldValue = <TValue = any>(path: string, value: TValue | null | undefined, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean, notifyForm?: boolean, notifyField?: boolean) => {
        /* get the existing value of the field using the property path */
        const existingValue = get(this.values, path);

        /* Only update if value different from the current value (reference comparison) */
        if (value !== existingValue) {
            /* check to see if subfields need to be processed. */
            const processChangedSubfields = updateDirty !== false || updateTouched !== false || updateFocused !== false || notifyField !== false;

            /* get affected subfield property paths. */
            const updatedPaths = processChangedSubfields ? this.updateValuePaths(existingValue, value ?? {}, path) : [];

            /* set value of the field */
            this.values = { ...set(this.values, path, value) };

            /* create object to track what the form needs to be notified of */
            const formChanges: IFormSubscription = {
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                touched: false,
                values: true,
                submitting: false,
                validating: false
            }

            /* process subfield updates if required. */
            if (processChangedSubfields) {
                updatedPaths.forEach(updatedPath => {
                    /* update subfield dirty state (if requested) */
                    const notifyDirty = updateDirty !== false && this.setFieldDirty(updatedPath, true, false, false);

                    /* update subfield focused state (if requested) */
                    const notifyFocused = updateFocused !== false && this.setFieldFocused(updatedPath, false, false, false, false);

                    /* update subfield touched state (if requested) */
                    const notifyTouched = updateTouched !== false && this.setFieldTouched(updatedPath, true, false, false);

                    /* update form notification requirements */
                    formChanges.focused = formChanges.focused || notifyFocused;
                    formChanges.touched = formChanges.touched || notifyTouched;
                    formChanges.dirty = formChanges.dirty || notifyDirty;

                    /* notify subfield listeners of applied changes (if requested) */
                    if (notifyField !== false) {
                        this.notifyFieldChange(updatedPath, {
                            dirty: notifyDirty,
                            errors: false,
                            focused: notifyFocused,
                            initialValue: false,
                            touched: notifyTouched,
                            value: true
                        });
                    }
                });
            }


            /* update field dirty state (if requested) */
            const notifyFieldDirty = updateDirty !== false && this.setFieldDirty(path, true, false, false);

            /* update field focused state (if requested) */
            const notifyFieldFocused = updateFocused !== false && this.setFieldFocused(path, false, false, false, true);

            /* update field touched state (if requested) */
            const notifyFieldTouched = updateTouched !== false && this.setFieldTouched(path, true, false, false);

            /* update form notification requirements */
            formChanges.focused = formChanges.focused || notifyFieldFocused;
            formChanges.touched = formChanges.touched || notifyFieldTouched;
            formChanges.dirty = formChanges.dirty || notifyFieldDirty;

            /* notify field listeners of applied changes (if requested) */
            if (notifyField !== false) {
                this.notifyFieldChange(path, {
                    dirty: notifyFieldDirty,
                    errors: false,
                    focused: notifyFieldFocused,
                    initialValue: false,
                    touched: notifyFieldTouched,
                    value: true
                });
            }

            /* notify form listeners of applied changes (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange(formChanges);
            }

            /* Run validation if validation is configured to run on change. */
            if (this.validateOn === ValidateOn.onChange) {
                this.validate();
            }

            /* Return true to signify that the field was updated. */
            return true;
        }

        /* Return false to signify that the field was NOT updated. */
        return false;
    }

    /**
     * Get the value of a field
     * @param path The fields property path.
     */
    public getFieldValue = <TValue = any>(path: string) => {
        return get(this.values, path) as TValue;
    }

    /**
     * Get the initial value of a field
     * @param path The fields property path.
     */
    public getFieldInitialValue = <TValue = any>(path: string) => {
        return get(this.initialValues, path) as TValue;
    }

    /**
     * Sets the touched state of a field.
     * @param path The fields property path.
     * @param touched Whether the field has been touched or not.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyField Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFieldTouched = (path: string, touched: boolean, notifyForm?: boolean, notifyField?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (touched !== this.touched[path]) {

            /* set fields touched state */
            this.touched = { ...this.touched, [path]: touched };

            /* Notify field listners (if requested) */
            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: false,
                        errors: false,
                        focused: false,
                        initialValue: false,
                        touched: true,
                        value: false
                    }
                );
            }

            /* Notify form listners (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: true,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* Return true to signify that the touched state was updated */
            return true;
        }

        /* Return false to signify that the touched state was not updated */
        return false;
    }

    /**
     * Get the touched state of a field
     * @param path The fields property path.
     */
    public getFieldTouched = (path: string) => {
        return this.touched[path] ?? false;
    }

    /**
     * Sets the dirty state of a field.
     * @param path The fields property path.
     * @param dirty Whether the field is dirty or not.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyField Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFieldDirty = (path: string, dirty: boolean, notifyForm?: boolean, notifyField?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (dirty !== this.dirty[path]) {

            /* set fields dirty state */
            this.dirty = { ...this.dirty, [path]: dirty };

            /* Notify field listners (if requested) */
            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: true,
                        errors: false,
                        focused: false,
                        initialValue: false,
                        touched: false,
                        value: false
                    }
                );
            }

            /* Notify form listners (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: true,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* Return true to signify that the dirty state was updated */
            return true;
        }

        /* Return false to signify that the dirty state was not updated */
        return false;
    }

    /**
     * Get the dirty state of a field
     * @param path The fields property path.
     */
    public getFieldDirty = (path: string) => {
        return this.dirty[path] ?? false;
    }

    /**
     * Sets the focused state of a field.
     * @param path The fields property path.
     * @param focused Whether the field is focused or not.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyField Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     * @param notifyPreviousFields Whether or not to notify the previously focused field's listeners of the change (default: true).
     */
    public setFieldFocused = (path: string, focused: boolean, notifyForm?: boolean, notifyField?: boolean, notifyPreviousFields?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (focused !== this.focused[path]) {
            /* find previous focused object paths */
            const previouslyFocused = Object.keys(this.focused).filter(key => this.focused[key] === true && key !== path);

            let validate = false;

            let touched = false;

            let previousFieldsTouched = false;

            if (!focused) {
                /* 
                 * If field was focused and is now no longer focused update touched state and flag for validation.
                 */
                if (this.focused[path]) {
                    touched = this.setFieldTouched(path, true, false, false);

                    validate = true;
                }

                /* clear focused fields */
                this.focused = {};
            }
            else {
                /* set current property path as focused element */
                this.focused = { [path]: focused };
            }

            /* Notify field listners (if requested) */
            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: false,
                        errors: false,
                        focused: true,
                        initialValue: false,
                        touched: touched,
                        value: false
                    }
                );
            }

            /* Process previously focused fields. */
            if (previouslyFocused.length > 0) {
                previouslyFocused.forEach(previousFocus => {
                    /* Mark previously focused field as touched. */
                    const previousTouched = this.setFieldTouched(previousFocus, true, false, false);

                    /* notify previously focused field of any changes (if requested) */
                    if (notifyPreviousFields !== false) {
                        this.notifyFieldChange(
                            previousFocus,
                            {
                                dirty: false,
                                errors: false,
                                focused: true,
                                initialValue: false,
                                touched: previousTouched,
                                value: false
                            }
                        );
                    }

                    previousFieldsTouched = previousFieldsTouched || previousTouched;
                });

                /* set flag to to determine if we need to run validation later because of blurred fields */
                validate = true;
            }


            /* Notify form listners (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: true,
                    initialValues: false,
                    touched: touched || previousFieldsTouched,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            /* if a field was blurred and validation is set to onBlur run validation */
            if (this.validateOn === ValidateOn.onBlur && validate) {
                this.validate();
            }


            /* Return true to signify that the focused state was updated */
            return true;
        }

        /* Return false to signify that the focused state was not updated */
        return false;
    }

    /**
     * Get the focused state of a field
     * @param path The fields property path.
     */
    public getFieldFocused = (path: string) => {
        return this.focused[path] ?? false;
    }

    /**
     * Sets the error state of a field.
     * @param path The fields property path.
     * @param errors The fields error state.
     * @param notifyForm Whether or not to notify the form listeners of the change (default: true).
     * @param notifyField Whether or not to notify the field listeners (affected fields only) of the change (default: true).
     */
    public setFieldErrors = (path: string, errors: TError[] | null, notifyForm?: boolean, notifyField?: boolean) => {
        /* Only update if value different from the current value (reference comparison) */
        if (errors !== this.errors[path]) {

            /* set fields error state */
            this.errors = { ...this.errors, [path]: errors ?? [] };


            /* Notify field listners (if requested) */
            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: false,
                        errors: true,
                        focused: false,
                        initialValue: false,
                        touched: false,
                        value: false
                    }
                );
            }

            /* Notify form listners (if requested) */
            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: true,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }


            /* Return true to signify that the error state was updated */
            return true;
        }

        /* Return false to signify that the error state was not updated */
        return false;
    }

    /**
     * Get the error state of a field
     * @param path The fields property path.
     */
    public getFieldErrors = (path: string) => {
        return this.errors[path] ?? [];
    }

    /**
     * Notify all form listeners of changes to the form.
     * @param changes The parts of the form state with changes.
     */
    public notifyFormChange = (changes: IFormSubscription) => {
        /* Find all listeners that need to be notified */
        const listeners = this.formListeners.filter(l => this.requiresFormUpdate(changes, l.subscription));

        /* Early exit if no listeners to notify */
        if (listeners.length === 0) {
            return;
        }

        /* Get new form state values to provide listeners. */
        const values = this.getFormValues();
        const errors = this.getFormErrors();
        const focused = this.getFormFocused();
        const initialValues = this.getFormInitialValues();
        const touched = this.getFormTouched();
        const dirty = this.getFormDirty();
        const submitting = this.getFormSubmitting();
        const validating = this.getFormValidating();
        const fields = this.getFields();

        /* send new form state to listeners */
        listeners.forEach(l => {
            l.subscriber({
                values,
                dirty,
                errors,
                fields,
                focused,
                initialValues,
                touched,
                submitting,
                validating
            });
        });
    }

    /**
     * Notify all field listeners of a particular field of changes to the field.
     * @param path The property path of the field.
     * @param changes The parts of the field state that have changed.
     */
    public notifyFieldChange = (path: string, changes: IFieldSubscription) => {
        /* Find all listeners that need to be notified */
        const listeners = this.fieldListeners.filter(l => l.path === path && this.requiresFieldUpdate(changes, l.subscription));

        /* Early exit if no listeners to notify */
        if (listeners.length === 0) {
            return;
        }

        /* Get new field state values to provide listeners. */
        const value = this.getFieldValue(path);
        const errors = this.getFieldErrors(path);
        const focused = this.getFieldFocused(path);
        const initialValue = this.getFieldInitialValue(path);
        const touched = this.getFieldTouched(path);
        const dirty = this.getFieldDirty(path);

        /* send new field state to listeners */
        listeners.forEach(l => {
            l.subscriber({
                value,
                dirty,
                errors,
                focused,
                initialValue,
                touched
            });
        });
    }

    /**
     * Notify all field listeners of changes to the form.
     * @param changes The parts of form state that have been changed.
     */
    public notifyAllFields = (changes: IFieldSubscription) => {
        const listenerPaths = new Set(this.fieldListeners.map(l => l.path));

        listenerPaths.forEach(path => {
            this.notifyFieldChange(
                path,
                changes
            );
        });
    }

    /**
     * Add a form listener to the form.
     * @param subscriber Event to run when notifying the listener.
     * @param subscription What changes should notify the listener.
     */
    public subscribeToForm = (subscriber: IFormSubscriber<TValues, TError>, subscription?: IFormSubscription | null): IUnsubscribe => {
        /* Create a listener from the provided data */
        const listener: IFormListener<TValues, TError> = {
            subscriber,
            subscription: subscription ?? {
                dirty: true,
                errors: true,
                fields: true,
                focused: true,
                initialValues: true,
                touched: true,
                values: true,
                submitting: true,
                validating: true
            }
        }

        /* register the listener */
        this.formListeners.push(listener);

        /* return unsubscribe function */
        return () => {
            this.formListeners = this.formListeners.filter(l => l !== listener);
        };
    }

    /**
     * Add a field listener to the form.
     * @param path The fields property path.
     * @param subscriber The event to run when notifying the listener.
     * @param subscription What changes should notify the listener.
     */
    public subscribeToField = <TValue = any>(path: string, subscriber: IFieldSubscriber<TValue, TError>, subscription?: IFieldSubscription | null): IUnsubscribe => {
        /* Create a listener from the provided data */
        const listener: IFieldListener<TValue, TError> = {
            path,
            subscriber,
            subscription: subscription ?? {
                dirty: true,
                errors: true,
                focused: true,
                initialValue: true,
                touched: true,
                value: true
            }
        }

        /* register the listener */
        this.fieldListeners.push(listener);

        /* return unsubscribe function */
        return () => {
            this.fieldListeners = this.fieldListeners.filter(l => l !== listener);
        };
    }

    /**
     * Validate the form. 
     */
    public validate = async () => {
        /* set validating state to true and notify the form and fields */
        this.setFormValidating(true, true);

        /* if a validation function exists run the validation */
        if (this.onValidate) {
            const errors = await this.onValidate(this.getFormState(), this.getFormActions());

            this.setFormErrors(errors);

            this.setFormValidating(false, true);

            return;
        }

        /* if no validation function clear errors */
        this.setFormErrors({});

        this.setFormValidating(false, true);
    }

    /**
     * Mark all fields as touched.
     * 
     * This is used to ensure that all fields are marked as touched when submitting.
     */
    private setAllTouched = () => {
        let touchedUpdate = false;

        this.fields.forEach(path => {
            const updated = this.setFieldTouched(path, true, false, true);

            touchedUpdate = touchedUpdate || updated;
        });

        if (touchedUpdate) {
            this.notifyFormChange({
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                touched: true,
                values: false,
                submitting: false,
                validating: false
            })
        }
    }

    private getFormState = (): IFormState<TValues, TError> => {
        return {
            values: this.getFormValues(),
            dirty: this.getFormDirty(),
            touched: this.getFormTouched(),
            fields: this.getFields(),
            errors: this.getFormErrors(),
            focused: this.getFormFocused(),
            initialValues: this.getFormInitialValues(),
            submitting: this.getFormSubmitting(),
            validating: this.getFormValidating()
        }
    };

    private getFormActions = (): IFormActions<TValues, TError> => {
        return {
            getValues: this.getFormValues,
            getTouched: this.getFormTouched,
            getDirty: this.getFormDirty,
            getFocused: this.getFormFocused,
            getErrors: this.getFormErrors,
            getInitialValues: this.getFormInitialValues,
            getSubmitting: this.getFormSubmitting,
            getValidating: this.getFormValidating,
            setValues: this.setFormValues,
            setTouched: this.setFormTouched,
            setDirty: this.setFormDirty,
            setFocused: this.setFormFocused,
            setErrors: this.setFormErrors,
            setValidating: this.setFormValidating,
            setSubmitting: this.setFormSubmitting,
            getFieldValue: this.getFieldValue,
            getFieldTouched: this.getFieldTouched,
            getFieldDirty: this.getFieldDirty,
            getFieldFocused: this.getFieldFocused,
            getFieldErrors: this.getFieldErrors,
            getFieldInitialValue: this.getFieldInitialValue,
            setFieldValue: this.setFieldValue,
            setFieldTouched: this.setFieldTouched,
            setFieldDirty: this.setFieldDirty,
            setFieldFocused: this.setFieldFocused,
            setFieldErrors: this.setFieldErrors,
            reset: this.reset,
            submit: this.submit,
            validate: this.validate,
            subscribe: this.subscribeToForm,
            subscribeToField: this.subscribeToField,
            getFields: this.getFields,
            registerField: this.registerField,
            unregisterField: this.unregisterField,
            notifyFormChange: this.notifyFormChange,
            notifyFieldChange: this.notifyFieldChange
        }
    };

    /**
     * Submit the form.
     */
    public submit = async () => {
        /* Early exit for if form is already submitting */
        if (this.getFormSubmitting()) {
            return;
        }

        /* set form submitting state */
        this.setFormSubmitting(true, true);

        let continueSubmit = true;
        let validationFailure = false;

        try {
            /* validate the form */
            await this.validate();

            /* set all fields to touched */
            this.setAllTouched();

            /* check to see if submission is allowed based on form state */
            if (this.allowSubmit) {
                /* use provided allow submit function to determine if submission is allowed */
                continueSubmit = await this.allowSubmit(this.getFormState(), this.getFormActions());
            }
            else {
                /* use default "if there are no errors" to determine if submission is allowed */
                continueSubmit = !this.hasErrors();
            }
        }
        catch (error) {
            continueSubmit = false;
            validationFailure = true;

            if (isDevelopment) {
                console.error(error)
            }
        }

        /* Early exit for if validation failed. */
        if (!continueSubmit) {

            /* set form submitting state to false */
            this.setFormSubmitting(false, true);


            /* Run validation failed event (if provided). */
            if (this.onSubmitValidationFailed) {
                try {
                    const result = await this.onSubmitValidationFailed(this.getFormState(), this.getFormActions(), validationFailure);

                    if (result) {
                        this.setFormErrors(result);
                    }
                }
                catch (error) {

                    if (isDevelopment) {
                        console.error(error)
                    }

                }
            }

            return;
        }

        /* Run submission event (if provided) */
        if (this.onSubmit) {
            try {
                const result = await this.onSubmit(this.getFormState(), this.getFormActions());

                if (result) {
                    this.setFormErrors(result);
                }
            }
            catch (error) {

                if (isDevelopment) {
                    console.error(error)
                }

                if (this.onSubmitFailed) {
                    try {
                        const result = await this.onSubmitFailed(this.getFormState(), this.getFormActions());

                        if (result) {
                            this.setFormErrors(result);
                        }
                    }
                    catch (error) {
                        if (isDevelopment) {
                            console.error(error)
                        }
                    }
                }
            }
        }

        this.setFormSubmitting(false, true);
    }

    private requiresFieldUpdate = (changes: IFieldSubscription, subscription: IFieldSubscription) => {
        return (changes.dirty && subscription.dirty) ||
            (changes.errors && subscription.errors) ||
            (changes.focused && subscription.focused) ||
            (changes.initialValue && subscription.initialValue) ||
            (changes.touched && subscription.touched) ||
            (changes.value && subscription.value);
    }

    private requiresFormUpdate = (changes: IFormSubscription, subscription: IFormSubscription) => {
        return (changes.dirty && subscription.dirty) ||
            (changes.errors && subscription.errors) ||
            (changes.focused && subscription.focused) ||
            (changes.initialValues && subscription.initialValues) ||
            (changes.touched && subscription.touched) ||
            (changes.values && subscription.values) ||
            (changes.submitting && subscription.submitting) ||
            (changes.validating && subscription.validating) ||
            (changes.fields && subscription.fields);
    }

    private updatedStatePaths = (previous: Record<string, any>, next: Record<string, any>) => {
        const previousKeys: string[] = previous ? Object.keys(previous) : [];
        const nextKeys: string[] = next ? Object.keys(next) : [];

        const changedPaths: string[] = previousKeys
            .filter(previousKey => !nextKeys.includes(previousKey) || previous[previousKey] !== next[previousKey])
            .concat(nextKeys.filter(nextKey => !previousKeys.includes(nextKey)));

        return changedPaths;
    }

    private updateValuePaths = (previous: any, next: any, parentPath?: string) => {
        const previousPaths: string[] = previous ? this.getValuePaths(previous) : [];
        const nextPaths: string[] = next ? this.getValuePaths(next) : [];

        const changedPaths: string[] = previousPaths
            .filter(previousPath => !nextPaths.includes(previousPath) || get(previous, previousPath) !== get(next, previousPath))
            .concat(nextPaths.filter(nextKey => !previousPaths.includes(nextKey)));

        if (parentPath) {
            return changedPaths.map(path => {
                if (path.startsWith('[')) {
                    return `${parentPath}${path}`;
                }

                return `${parentPath}.${path}`;
            });
        }

        return changedPaths;
    }

    private getValuePaths = (values: any) => {
        return getDeepKeys(values);
    }

    private hasErrors = () => {
        const errorPaths = Object.keys(this.errors);

        return errorPaths.some(path => this.errors[path]?.length > 0);
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormManager;