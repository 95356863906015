/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import FieldProvider from '../../../FieldProvider';
import FormLabel from '../../../FormLabel';
import { TableCell} from '@material-ui/core';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IGroupedSubformGridHeaderProps extends IGroupedSubformGridHeaderStyleProps {
    name: string;
    content?: React.ReactNode;
    className?: string;
    sortDirection?: SortDirection;
    updateSorting: (name: string, include: boolean) => void;
}

interface IGroupedSubformGridHeaderStyleProps {
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
    canSort?: boolean;
}

export enum SortDirection {
    Ascending = 1,
    Descending = 2
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    header: {
        width: (props: IGroupedSubformGridHeaderStyleProps) => props.width ? props.width : undefined,
        minWidth: (props: IGroupedSubformGridHeaderStyleProps) => props.minWidth ? props.minWidth : undefined,
        maxWidth: (props: IGroupedSubformGridHeaderStyleProps) => props.maxWidth ? props.maxWidth : undefined,
        cursor: (props: IGroupedSubformGridHeaderStyleProps) => props.canSort ? 'pointer' : undefined,

        '& > *': {
            fontWeight: 'bold'
        }
    },
    flexWrapper: {
        display: 'flex'
    },
    icon: {
        marginLeft: theme.spacing(1)
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const GroupedSubformGridHeader: React.FunctionComponent<IGroupedSubformGridHeaderProps> = ({
    name,
    content,
    width,
    minWidth,
    maxWidth,
    className,
    sortDirection,
    canSort,
    updateSorting
}) => {

    const classes = useStyles({ width, minWidth, maxWidth, canSort });

    const onClick = React.useCallback((e: React.MouseEvent) => {
        updateSorting(name, e.shiftKey)
    }, [updateSorting, name])

    return (
        <FieldProvider name={`[0].${name}`} autoRegister={false}>
            <TableCell className={classNames(classes.header, { [className ?? '']: !!className })} onClick={canSort ? onClick : undefined}>
                <div className={classes.flexWrapper}>
                    {
                        content ?
                            content :
                            <FormLabel />
                    }
                    {
                        canSort && sortDirection === SortDirection.Ascending && (
                            <div className={classes.icon}>
                                <FontAwesomeIcon icon={faArrowUp} />
                            </div>
                        )
                    }
                    {
                        canSort && sortDirection === SortDirection.Descending && (
                            <div className={classes.icon}>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </div>
                        )
                    }
                </div>
            </TableCell>
        </FieldProvider>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default GroupedSubformGridHeader;
