import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormDefinitionSingleResponse, FormDefinitionGetSingleById, FormMetadata, IForm, ResponseStatus, IValidationResult } from '../../api/dtos';
import { BoundMutator } from '../../utilities/BoundMutator';
import FormsContext from '../../contexts/FormsContext';
import { Forms } from '../../Forms';

import { IDataSingleResponse, IValidationResponse } from '../../api/response';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TForm extends IForm = IForm>(url: string, forms: Forms, formMetadata: FormMetadata, id?: number, data?: TForm, metadata?: Record<string, any>) => {
    if (!id) {
        return await create<TForm>(forms, formMetadata, data, metadata);
    }

    return await get<TForm>(forms, formMetadata, id)
}

const create = async <TForm extends IForm = IForm>(forms: Forms, formMetadata: FormMetadata, data?: TForm, metadata?: Record<string, any>) => {
    const requestType = forms.dtos[`${formMetadata.name}PostCreate`]

    const response: IDataSingleResponse<TForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const get = async <TForm extends IForm = IForm>(forms: Forms, formMetadata: FormMetadata, id: number) => {
    const requestType = forms.dtos[`${formMetadata.name}GetSingleById`]

    const response: IDataSingleResponse<TForm> = await forms.serviceStackClient.get(new requestType({ id }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TForm extends IForm = IForm>(forms: Forms, formMetadata: FormMetadata, data: TForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostSave`]

    const response: IDataSingleResponse<TForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const validate = async <TForm extends IForm = IForm, TValidationResult extends IValidationResult = IValidationResult>(forms: Forms, formMetadata: FormMetadata, data: TForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostValidate`]

    const response: IValidationResponse<TValidationResult> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const useFormDataById = <TForm extends IForm = IForm, TValidationResult extends IValidationResult = IValidationResult>(formDefinitionIdentifier?: number | string | null, id?: number | null, createData?: TForm, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<IDataSingleResponse<TForm>, IDataSingleResponse<TForm>>) => {
    const forms = useContext(FormsContext);

    const formMetadata = useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return forms.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier);
        }

        return forms.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier);
    }, [forms.formMetadata, formDefinitionIdentifier]);

    const cacheKey = useMemo(() => {
        if (!formMetadata) {
            return null;
        }

        if (!id) {
            return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/single/id`, forms, formMetadata, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/single/id`, forms, formMetadata, id, null, null]
    }, [forms, id, formMetadata, createData, createMetadata])

    const { data, error } = useSWR<IDataSingleResponse<TForm>, IDataSingleResponse<TForm>>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<IDataSingleResponse<TForm>> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TForm, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        if (formMetadata) {

            const response = await save<TForm>(forms, formMetadata, saveData, metadata);

            if (cacheKey) {
                // !!cacheKey[3] = has an id.
                const revalidate = shouldRevalidate ?? !!cacheKey[3];

                const updated = await boundMutate(response, revalidate);

                return updated?.data;
            }

            return response?.data;
        }

        return null;
    }, [boundMutate, forms, formMetadata, cacheKey]);

    const boundValidate = useCallback(async (validateData: TForm, metadata?: Record<string, any>) => {
        if (formMetadata) {
            const response = await validate<TForm, TValidationResult>(forms, formMetadata, validateData, metadata);

            return response?.validationResult;
        }

        return null;
    }, [boundMutate, forms, formMetadata]);


    const result = useMemo(() => {
        return {
            data: data?.data as TForm | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default useFormDataById;