import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';
import { FormErrorBoundary, FormErrorBoundaryMode, IFieldProps as IBaseFieldProps, InputComponentType, OmitInputRender } from '@ngt/forms-core';
import isString from 'lodash-es/isString';
import * as React from 'react';
import GroupedFieldsContext from '../../../../contexts/form/GroupedFieldsContext';
import FormOptionsContext, { FieldErrorOption } from '../../../../contexts/form/FormOptionsContext';
import LabelContext from '../../../../contexts/form/LabelContext';
import FieldProvider from '../../FieldProvider';
import FormErrorHandler from '../../FormErrorHandler';
import FormLabel, { IFormLabelProps } from '../../FormLabel';
import Input, { IInputProps } from '../../Input';
import LookupFilter, { ILookupFilterProps } from '../../LookupFilter';
import GroupedErrorDisplay, { IGroupedErrorDisplayProps } from './GroupedErrorDisplay';
import GroupedErrorFlag, { ShowErrorFlagFunction } from './GroupedErrorFlag';

type OmitProps<T> = Omit<T, 'name' | 'label'>;

interface IFieldOnlyProps<TValue = any> extends OmitProps<IBaseFieldProps<TValue>> {
    errorMode?: FormErrorBoundaryMode;
}

export interface IGroupedFieldProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TForm extends object = any
    > extends IInputProps<TComponent, TValue> {
    name: string;
    label?: React.ReactNode;
    LabelProps?: IGroupedFieldLabelProps;
    FieldProps?: IFieldOnlyProps<TValue>;
    LookupFilterProps?: ILookupFilterProps<TForm>;
    GroupStyleProps?: IGroupedFieldStyleProps;
    ErrorDisplayProps?: IGroupedFieldErrorDisplayProps;
    WrappingComponent?: React.ComponentType;
}



export interface IGroupedFieldLabelProps extends Omit<IFormLabelProps, 'label'> {
    hideLabel?: boolean;
}

export interface IGroupedFieldErrorDisplayProps extends Omit<IGroupedErrorDisplayProps, 'fieldErrorOption'> {
    showErrorFlag?: ShowErrorFlagFunction
}

export interface IGroupedFieldStyleProps {
    fieldColor?: 'Auto' | 'Inherit' | 'None';
    paddingTop?: boolean | number;
    paddingBottom?: boolean | number;
    isGrouped?: boolean;
    hideLabel?: boolean;
    xs?: GridProps['xs'];
    sm?: GridProps['sm'];
    md?: GridProps['md'];
    lg?: GridProps['lg'];
    xl?: GridProps['xl'];
}

const useStyles = makeStyles<Theme, IGroupedFieldStyleProps>(theme => ({
    container: {
        borderBottom: props => props.fieldColor === 'Auto' || !props.fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
        borderTop: props => props.fieldColor === 'Auto' || !props.fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
        marginTop: props => props.fieldColor === 'Auto' || !props.fieldColor ? -1 : undefined,

        '&:nth-child(even)': {
            background: props => props.fieldColor === 'Auto' || !props.fieldColor ? theme.palette.grey[100] : undefined
        },

        '&:nth-child(odd)': {
            background: props => props.fieldColor === 'Auto' || !props.fieldColor ? theme.palette.common.white : undefined
        },

        '&:first-child $grid': {
            paddingTop: props => props.paddingTop === undefined && props.isGrouped ?
                theme.spacing(3) :
                undefined
        },

        '&:last-child $grid': {
            paddingBottom: props => props.paddingBottom === undefined && props.isGrouped ?
                theme.spacing(3) :
                undefined
        }
    },
    grid: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: props => props.paddingTop === false ?
            theme.spacing(0) :
            props.paddingTop === true ?
                theme.spacing(2) :
                props.paddingTop === undefined && props.isGrouped ?
                    theme.spacing(1) :
                    props.paddingTop === undefined ?
                        theme.spacing(2) :
                        theme.spacing(props.paddingTop),
        paddingBottom: props => props.paddingBottom === false ?
            theme.spacing(0) :
            props.paddingBottom === true ?
                theme.spacing(2) :
                props.paddingBottom === undefined && props.isGrouped ?
                    theme.spacing(1) :
                    props.paddingBottom === undefined ?
                        theme.spacing(2) :
                        theme.spacing(props.paddingBottom),

        //paddingTop: props => props.paddingTop === false ?
        //    theme.spacing(0) :
        //    props.paddingTop === true ?
        //        theme.spacing(3) :
        //        props.paddingTop === undefined ?
        //            theme.spacing(3) :
        //            theme.spacing(props.paddingTop),

        //paddingBottom: props => props.paddingBottom === false ?
        //    theme.spacing(0) :
        //    props.paddingBottom === true ?
        //        theme.spacing(3) :
        //        props.paddingBottom === undefined ?
        //            theme.spacing(3) :
        //            theme.spacing(props.paddingBottom),
    },
    field: {
        [theme.breakpoints.only('xl')]: {
            paddingTop: props => (props.xl ?? 3) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('lg')]: {
            paddingTop: props => (props.lg ?? 3) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: props => (props.md ?? 4) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: props => (props.sm ?? 12) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: props => (props.xs ?? 12) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        }
    },
    inputContainer: {
        display: 'flex'
    },
    input: {
        minWidth: 0,
        maxWidth: 'calc(100% - 32px)',
        flex: '1 1 auto'
    },
    label: {
        paddingTop: 4
    }
}));

const GroupedField = <
    TComponent extends InputComponentType = any,
    TValue = any
>({
    name,
    label,
    fieldErrorOption,
    LabelProps: { hideLabel, ...labelProps } = {},
    FieldProps: { errorMode = FormErrorBoundaryMode.Contain, ...fieldProps } = { errorMode: FormErrorBoundaryMode.Contain },
    LookupFilterProps,
    GroupStyleProps,
    ErrorDisplayProps,
    WrappingComponent,
    ...props
}: IGroupedFieldProps<TComponent, TValue> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {

    const isGrouped = React.useContext(GroupedFieldsContext);

    const formOptions = React.useContext(FormOptionsContext);

    const fieldErrorOptionToUse = fieldErrorOption ?? formOptions.fieldErrors;

    const classes = useStyles({
        ...GroupStyleProps,
        fieldColor: isGrouped ? 'Inherit' : GroupStyleProps?.fieldColor,
    });

    let internal = (
        <Input name={name} {...props} label={null} fieldErrorOption={FieldErrorOption.AutoHideErrorMessages} />
    );

    if (fieldErrorOptionToUse !== FieldErrorOption.AlwaysHideErrors) {
        internal = (
            <div
                className={classes.inputContainer}
            >
                <div
                    className={classes.input}
                >
                    {internal}
                </div>
                <GroupedErrorFlag showErrorFlag={ErrorDisplayProps?.showErrorFlag} showErrorFlagExt={ErrorDisplayProps?.showError} />
            </div>
        )
    }

    if(LookupFilterProps)
    {
        internal = <LookupFilter {...LookupFilterProps}>
            {internal}
        </LookupFilter>;
    }

    if (WrappingComponent) {
        internal = <WrappingComponent>
            {internal}
        </WrappingComponent>
    }

    return (
        <div
            className={classes.container}
        >
            <FormErrorHandler
                errorMode={errorMode}
            >
                <FieldProvider name={name} {...fieldProps}>
                    <Grid
                        container
                        className={classes.grid}
                    >
                        {
                            hideLabel !== true && (
                                <Grid
                                    item
                                    className={classes.label}
                                    xs={(GroupStyleProps?.xs ? GroupStyleProps?.xs === 'auto' || GroupStyleProps?.xs === 12 ? 'auto' : 12 - (GroupStyleProps?.xs as number) : 12) as any}
                                    sm={(GroupStyleProps?.sm ? GroupStyleProps?.sm === 'auto' || GroupStyleProps?.sm === 12 ? 'auto' : 12 - (GroupStyleProps?.sm as number) : 12) as any}
                                    md={(GroupStyleProps?.md ? GroupStyleProps?.md === 'auto' || GroupStyleProps?.md === 12 ? 'auto' : 12 - (GroupStyleProps?.md as number) : 8) as any}
                                    lg={(GroupStyleProps?.lg ? GroupStyleProps?.lg === 'auto' || GroupStyleProps?.lg === 12 ? 'auto' : 12 - (GroupStyleProps?.lg as number) : 9) as any}
                                    xl={(GroupStyleProps?.xl ? GroupStyleProps?.xl === 'auto' || GroupStyleProps?.xl === 12 ? 'auto' : 12 - (GroupStyleProps?.xl as number) : 9) as any}
                                >
                                    {
                                        label === undefined || isString(label) ?
                                            <FormLabel {...labelProps} label={label} /> :
                                            label

                                    }
                                </Grid>
                            )
                        }
                        <Grid
                            item
                            xs={GroupStyleProps?.xs ?? 12}
                            sm={GroupStyleProps?.sm ?? 12}
                            md={GroupStyleProps?.md ?? 4}
                            lg={GroupStyleProps?.lg ?? 3}
                            xl={GroupStyleProps?.xl ?? 3}
                            className={classes.field}
                        >
                            {internal}
                        </Grid>
                    </Grid>
                </FieldProvider>
                <GroupedErrorDisplay
                    {...ErrorDisplayProps}
                    fieldErrorOption={fieldErrorOption}
                />
            </FormErrorHandler>
        </div>
    )
};

export default GroupedField;