/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '@ngt/forms-core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type TextAreaFieldsProps = Partial<Omit<MuiTextFieldProps, 'type'>> & IInputRenderProps<string, Dtos.IValidationError>

export interface ITextAreaProps extends TextAreaFieldsProps {
    errors?: Dtos.IValidationError[];
    hasErrors?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const TextArea: React.FunctionComponent<ITextAreaProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    fullWidth,
    onChange,
    inputProps,
    errors,
    hasErrors,
    ...rest
}) => {

    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }

        onInputChange(event.target.value && event.target.value !== '' ? event.target.value : undefined as any);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
    }, [inputProps?.onBlur, onBlur]);
        
    return (
        <MuiTextField
            {...rest as any}
            multiline
            rows={rest.rows ?? 1}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
            name={name}
            value={value ?? ''}
            onChange={onChangeCombined}
            error={hasErrors}
            helperText={errors && errors.length > 0 && errors?.map((error, i) => (<span key={i}>{error.message}</span>))}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default TextArea;
