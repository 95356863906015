/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, darken, lighten } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronCircleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleDown';

import { faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleUp';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';

import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';

import ButtonBase from '@material-ui/core/ButtonBase';

import CircularProgress from '@material-ui/core/CircularProgress';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
interface IValidationErrorFlag extends IValidationErrorFlagStyleProps {
    validationErrors?: Dtos.IValidationError[] | null;
    loading?: boolean;
    showMultiple?: boolean;
    onClick?: (validationErrorType?: Dtos.ValidationErrorType) => void;
    hoverIcon?: IconDefinition;
}

interface IValidationErrorFlagStyleProps
{
    size?: 'default' | 'large';
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => {
    const getColor = theme.palette.type === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.type === 'light' ? lighten : darken;

    return {
        icon: {
            width: (props: IValidationErrorFlagStyleProps) => props.size === 'large' ? 50 : 32,
            minWidth: (props: IValidationErrorFlagStyleProps) => props.size === 'large' ? 50 : 32,
            minHeight: (props: IValidationErrorFlagStyleProps) => props.size === 'large' ? 50 : 32,
            height: (props: IValidationErrorFlagStyleProps) => props.size === 'large' ? 50 : 32,
            padding: theme.spacing(1, 1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderWidth: 1,
            borderStyle: 'solid',
            '& > *': {
                opacity: 0.9
            },
            '& $hover': {
                display: 'none'
            },
            '&:hover': {
                '& $hover': {
                    display: 'block'
                },
                '& $default': {
                    display: 'none'
                },
            }
        },
        loading: {
            padding: theme.spacing(0.25, 0.25),
            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
            '& > *': {
                color: theme.palette.grey[700],
            }
        },
        notes: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
            '& > *': {
                color: theme.palette.info.main,
            }
        },
        eligibilities: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            borderColor: getBorderColor(theme.palette.warning.main, 0.8),
            '& > *': {
                color: theme.palette.warning.main
            }
        },
        errors: {
            color: getColor(theme.palette.error.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
            borderColor: getBorderColor(theme.palette.error.main, 0.8),
            '& > *': {
                color: theme.palette.error.main,
            }
        },
        stratificationFailures: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.warning.main,
            borderColor: getColor(theme.palette.warning.main, 0.1),
        },
        criticals: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.error.main,
            borderColor: getColor(theme.palette.error.main, 0.1),
        },
        default: {
        },
        hover: {
        }
    }
});


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const ValidationErrorFlag: React.FunctionComponent<IValidationErrorFlag> = ({
    validationErrors,
    loading,
    onClick,
    showMultiple,
    hoverIcon,
    size
}) => {
    const classes = useStyles({ size });

    // const typedErrors: Dtos.IOpmsValidationError[] | undefined = error;

    const notes = validationErrors?.some(e => e.type === Dtos.ValidationErrorType.Warning);

    const lowPriorityErrors = validationErrors?.some(e => e.type === Dtos.ValidationErrorType.Low);

    const normalPriorityErrors = validationErrors?.some(e => e.type === Dtos.ValidationErrorType.Normal);

    const highPriorityErrors = validationErrors?.some(e => e.type === Dtos.ValidationErrorType.High);

    const criticals = validationErrors?.some(e => e.type === Dtos.ValidationErrorType.Critical);

    const onLoadingClick = React.useCallback(() => {
        if (onClick) {
            onClick(undefined);
        }
    }, [onClick]);

    const onNoteClick = React.useCallback(() => {
        if (onClick) {
            onClick(Dtos.ValidationErrorType.Warning);
        }
    }, [onClick]);

    const onEligibilityClick = React.useCallback(() => {
        if (onClick) {
            onClick(Dtos.ValidationErrorType.Warning);
        }
    }, [onClick]);

    const onErrorClick = React.useCallback(() => {
        if (onClick) {
            onClick(Dtos.ValidationErrorType.Warning);
        }
    }, [onClick]);

    const onStratificationClick = React.useCallback(() => {
        if (onClick) {
            onClick(Dtos.ValidationErrorType.Warning);
        }
    }, [onClick]);

    const onCriticalClick = React.useCallback(() => {
        if (onClick) {
            onClick(Dtos.ValidationErrorType.Warning);
        }
    }, [onClick]);

    if (loading) {
        return (
            <ButtonBase
                className={`${classes.icon} ${classes.loading}`}
                onClick={onLoadingClick}
            >
                <div>
                    <CircularProgress size={16} />
                </div>
            </ButtonBase>
        );
    }

    const calculatedHoverIcon = hoverIcon ? hoverIcon : faChevronCircleDown as any;

    return (
        <>
            {
                notes && ((!lowPriorityErrors && !normalPriorityErrors && !highPriorityErrors && !criticals) || showMultiple) && (
                    <ButtonBase
                        className={`${classes.icon} ${classes.notes}`}
                        onClick={onNoteClick}
                    >
                        <FontAwesomeIcon className={classes.default} icon={faInfoCircle} size={size === 'large' ? '2x' : undefined} fixedWidth />
                        <FontAwesomeIcon className={classes.hover} icon={calculatedHoverIcon} size={size === 'large' ? '2x' : undefined} fixedWidth />
                    </ButtonBase>
                )
            }
            {
                lowPriorityErrors && ((!normalPriorityErrors && !highPriorityErrors && !criticals) || showMultiple) && (
                    <ButtonBase
                        className={`${classes.icon} ${classes.eligibilities}`}
                        onClick={onEligibilityClick}
                    >
                        <FontAwesomeIcon className={classes.default} icon={faExclamationCircle} size={size === 'large' ? '2x' : undefined} fixedWidth />
                        <FontAwesomeIcon className={classes.hover} icon={calculatedHoverIcon} size={size === 'large' ? '2x' : undefined} fixedWidth />
                    </ButtonBase>
                )
            }
            {
                normalPriorityErrors && ((!highPriorityErrors && !criticals) || showMultiple) && (
                    <ButtonBase
                        className={`${classes.icon} ${classes.errors}`}
                        onClick={onErrorClick}
                    >
                        <FontAwesomeIcon className={classes.default} icon={faTimesCircle} size={size === 'large' ? '2x' : undefined} fixedWidth />
                        <FontAwesomeIcon className={classes.hover} icon={calculatedHoverIcon} size={size === 'large' ? '2x' : undefined} fixedWidth />
                    </ButtonBase>
                )
            }
            {
                highPriorityErrors && (!criticals || showMultiple) && (
                    <ButtonBase
                        className={`${classes.icon} ${classes.stratificationFailures}`}
                        onClick={onStratificationClick}
                    >
                        <FontAwesomeIcon className={classes.default} icon={faExclamationCircle} size={size === 'large' ? '2x' : undefined} fixedWidth />
                        <FontAwesomeIcon className={classes.hover} icon={calculatedHoverIcon} size={size === 'large' ? '2x' : undefined} fixedWidth />
                    </ButtonBase>
                )
            }
            {
                criticals && (
                    <ButtonBase
                        className={`${classes.icon} ${classes.criticals}`}
                        onClick={onCriticalClick}
                    >
                        <FontAwesomeIcon className={classes.default} icon={faTimesCircle} size={size === 'large' ? '2x' : undefined} fixedWidth />
                        <FontAwesomeIcon className={classes.hover} icon={calculatedHoverIcon} size={size === 'large' ? '2x' : undefined} fixedWidth />
                    </ButtonBase>
                )
            }
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ValidationErrorFlag;
