/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import useForm, { IUseFormOptions } from '../hooks/useForm';

import FormContext from '../contexts/FormContext';
import ConditionConfigurationContext, { DefaultConditionConfigurationContext, IConditionConfigurationContext } from '../contexts/ConditionConfigurationContext';
import FormErrorBoundary, { FormErrorBoundaryMode } from './FormErrorBoundary';
import FormErrorContext from '../contexts/FormErrorContext';
import FieldContext from '../contexts/FieldContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the Form component.
 */
export interface IFormProps<TValues extends object = any, TError = any> extends IUseFormOptions<TValues, TError> {
    onConditionNotMet?: IConditionConfigurationContext<TValues, TError>['onConditionNotMet'] | null;
    conditionView?: IConditionConfigurationContext<TValues, TError>['view'];
    children: React.ReactNode;
}

const emptyFieldContext = { name: undefined, actions: undefined } as any;

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const Form = <TValues extends object = any, TError = any>({
    ...props
}: IFormProps<TValues, TError>) => {
    return (
        <FormErrorBoundary mode={FormErrorBoundaryMode.Contain}>
            <InternalForm {...props} />
        </FormErrorBoundary>
    );
};
/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const InternalForm = <TValues extends object = any, TError = any>({
    children,
    conditionView,
    onConditionNotMet,
    ...useFormOptions
}: IFormProps<TValues, TError>) => {

    const formMethods = useForm(useFormOptions);

    const onSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        formMethods.submit();
    }, [formMethods.submit]);

    const conditionConfigurationContext = React.useMemo(() => {
        return {
            onConditionNotMet: onConditionNotMet === null ? undefined : (onConditionNotMet ?? DefaultConditionConfigurationContext.onConditionNotMet),
            view: conditionView ?? DefaultConditionConfigurationContext.view
        }
    }, [conditionView, onConditionNotMet]);

    const formErrorContext = React.useContext(FormErrorContext);

    React.useEffect(() => {
        if (formErrorContext?.addName && formErrorContext?.removeName) {
            formErrorContext?.addName('')

            return () => {
                formErrorContext?.removeName('');
            }
        }

        return () => { };
    }, [formErrorContext?.addName, formErrorContext?.removeName])

    return (
        <FieldContext.Provider value={emptyFieldContext}>
            <ConditionConfigurationContext.Provider value={conditionConfigurationContext}>
                <FormContext.Provider value={formMethods}>
                    <form onSubmit={onSubmit}>
                        {children}
                    </form>
                </FormContext.Provider>
            </ConditionConfigurationContext.Provider>
        </FieldContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Form;