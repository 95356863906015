import { FormErrorBoundary, FormErrorBoundaryMode, IFieldProps as IBaseFieldProps, InputComponentType, OmitInputRender } from '@ngt/forms-core';
import * as React from 'react';
import LabelContext from '../../../../contexts/form/LabelContext';
import FieldProvider from '../../FieldProvider';
import FormErrorHandler from '../../FormErrorHandler';
import Input, { IInputProps } from '../../Input';
import LookupFilter, { ILookupFilterProps } from '../../LookupFilter';

type OmitProps<T> = Omit<T, 'name'>;

interface IFieldOnlyProps<TValue = any> extends OmitProps<IBaseFieldProps<TValue>> {
    errorMode?: FormErrorBoundaryMode;
}

export interface IInputOnlyFieldProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TForm extends object = any
    > extends IInputProps<TComponent, TValue> {
    name: string;
    FieldProps?: IFieldOnlyProps<TValue>;
    LookupFilterProps?: ILookupFilterProps<TForm>;
    WrappingComponent?: React.ComponentType;
}

const InputOnlyField = <
    TComponent extends InputComponentType = any,
    TValue = any
>({
    name,
    FieldProps: { errorMode = FormErrorBoundaryMode.Contain, ...fieldProps } = { errorMode: FormErrorBoundaryMode.Contain },
    LookupFilterProps,
    WrappingComponent,
    ...props
}: IInputOnlyFieldProps<TComponent, TValue> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {

    let internal = <Input name={name} {...props} />;

    if (LookupFilterProps) {
        internal = <LookupFilter {...LookupFilterProps}>
            {internal}
        </LookupFilter>;
    }

    if (WrappingComponent) {
        internal = <WrappingComponent>
            {internal}
        </WrappingComponent>
    }

    return (
        <FormErrorHandler
            errorMode={errorMode}
        >
            <FieldProvider name={name} {...fieldProps}>
                {internal}
            </FieldProvider>
        </FormErrorHandler>
    )
};

export default InputOnlyField;