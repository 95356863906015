/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, darken, lighten } from '@material-ui/core/styles';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import IDtoClass from '../../utilities/IDtoClass';

import * as Dtos from '../../api/dtos';

import { Input as CoreInput, IInputProps as IInputBaseProps, InputComponentType, OmitInputRender } from '@ngt/forms-core';

import FormOptionsContext, { FieldErrorOption } from '../../contexts/form/FormOptionsContext';
import { useFieldErrors } from '../../hooks/form/useFieldErrors';
import LabelContext from '../../contexts/form/LabelContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


export interface IInputProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TError = Dtos.IValidationError
> extends IInputBaseProps<TComponent, TValue, TError> {
    disabled?: boolean;
    fieldErrorOption?: FieldErrorOption;
}

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Input = <
    TComponent extends InputComponentType = any,
    TValue = any,
    TError = Dtos.IValidationError
>({
    disabled,
    fieldErrorOption,
    label,
    ...props
}: IInputProps<TComponent, TValue, TError> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {
    const formOptions = React.useContext(FormOptionsContext);

    const labelContext = React.useContext(LabelContext)

    const inputDisabled = disabled || formOptions.readOnly;

    const inputFieldErrors = fieldErrorOption ?? formOptions.fieldErrors;

    const errors = useFieldErrors(inputFieldErrors);

    const labelToUse = label !== undefined ? label : labelContext?.label;

    return (
        <CoreInput
            disabled={inputDisabled}
            hasErrors={errors.hasErrors}
            errors={errors.errors}
            label={labelToUse}
            {...props as any}
        />
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Input;
