/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFormState } from '@ngt/forms-core';

import CollapsibleTable from '../../table/CollapsibleTable';
import { GridColDef } from '@material-ui/data-grid';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormFields component.
 */
export interface IFormFieldsProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: GridColDef[] = [
    {
        field: 'propertyName',
        headerName: 'Property Path',
        flex: 1
    }
]

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormFields: React.FunctionComponent<IFormFieldsProps> = ({
    children
}) => {
    const { fields } = useFormState({ fields: true });

    const data = React.useMemo(() => {
        if (!fields) {
            return [];
        }

        return fields.map((key, i) => ({ id: i, propertyName: key }))
    }, [fields])

    return (
        <CollapsibleTable
            title="Registered Fields"
            entityName="fields"
            loading={false}
            rows={data}
            columns={columns}
            startCollapsed
            autoHeight
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormFields;