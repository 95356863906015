/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';

/**
 * typings
 */
import { useTheme, useMediaQuery, makeStyles, darken, lighten } from '@material-ui/core';

import { useSnackbar as useNotistackSnackbar, OptionsObject, SnackbarContentCallback, SnackbarKey, SnackbarMessage, ProviderContext } from 'notistack';

import Alert, { AlertProps } from '@material-ui/lab/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';

import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';

import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as Dtos from '../api/dtos';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/*
* ---------------------------------------------------------------------------------
* Types
* ---------------------------------------------------------------------------------
*/

export type SnackbarVariant = "light" | "dark" | "success" | "emphasised-success" | "warning" | "error-low" | "error-normal" | "error-high" | "error-critical";


interface ISnackbarOptions extends Omit<OptionsObject, 'variant'> { variant?: SnackbarVariant; icon?: IconDefinition };

interface ISnackbarAlertProps {
    id: SnackbarKey;
    message: SnackbarMessage;
    variant: SnackbarVariant;
    closeSnackbar: ProviderContext['closeSnackbar']
    icon?: IconDefinition;
}

interface IStyleProps {
    variant: SnackbarVariant
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => {
    const getColor = theme.palette.type === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.type === 'light' ? lighten : darken;

    return {
        standardError: {
            color: (props: IStyleProps) => props.variant === 'light' ? getColor(theme.palette.grey[700], 0.6) : '',
            backgroundColor: (props: IStyleProps) => props.variant === 'light' ? getBackgroundColor(theme.palette.grey[700], 0.9) : '',
            borderColor: (props: IStyleProps) => props.variant === 'light' ? getBorderColor(theme.palette.grey[700], 0.8) : '',
        },
        filledError: {
            color: (props: IStyleProps) => props.variant === 'dark' ? '#FFF' : '',
            backgroundColor: (props: IStyleProps) => props.variant === 'dark' ? theme.palette.grey[700] : '',
            borderColor: (props: IStyleProps) => props.variant === 'dark' ? getColor(theme.palette.grey[700], 0.1) : ''
        }
    }
});

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/
const alertVariantMapping: Record<SnackbarVariant, AlertProps['variant']> = {
    light: 'standard',
    dark: 'filled',
    success: 'standard',
    'emphasised-success': 'filled',
    warning: 'standard',
    'error-low': 'standard',
    'error-normal': 'standard',
    'error-high': 'filled',
    'error-critical': 'filled'
}

const alertSeverityMapping: Record<SnackbarVariant, AlertProps['severity']> = {
    light: 'error',
    dark: 'error',
    success: 'success',
    'emphasised-success': 'success',
    warning: 'info',
    'error-low': 'warning',
    'error-normal': 'error',
    'error-high': 'warning',
    'error-critical': 'error'
}

const alertIconMapping: Record<SnackbarVariant, IconDefinition | undefined> = {
    light: undefined,
    dark: undefined,
    success: faCheckCircle,
    'emphasised-success': faCheckCircle,
    warning: faInfoCircle,
    'error-low': faExclamationCircle,
    'error-normal': faTimesCircle,
    'error-high': faExclamationCircle,
    'error-critical': faTimesCircle
}

const SnackbarAlert: React.FunctionComponent<ISnackbarAlertProps> = ({
    closeSnackbar,
    id,
    message,
    variant,
    icon
}) => {
    const classes = useStyles({ variant })

    let iconToUse = icon;

    if (!iconToUse) {
        iconToUse = alertIconMapping[variant];
    }

    return (
        <Alert
            onClose={() => closeSnackbar(id)}
            variant={alertVariantMapping[variant]}
            severity={alertSeverityMapping[variant]}
            icon={iconToUse ? <FontAwesomeIcon fixedWidth icon={iconToUse} /> : false}
            classes={classes}
        >
            {message}
        </Alert>
    );
};


const useSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

    const makeSnackbar = React.useCallback((message: SnackbarMessage, options?: ISnackbarOptions) => {
        if (options?.content) {
            const { variant, icon, ...originalOptions } = options;

            return enqueueSnackbar(message, originalOptions)
        }
        else {
            const nonNullOptions = options ?? {} as ISnackbarOptions;

            const { variant, icon, ...originalOptions } = nonNullOptions;

            const content: SnackbarContentCallback = (key: SnackbarKey, message: SnackbarMessage) => {
                return (
                    <div>
                        <SnackbarAlert
                            id={key}
                            closeSnackbar={closeSnackbar}
                            message={message}
                            variant={variant ?? 'light'}
                            icon={icon}
                        />
                    </div>
                );
            }

            return enqueueSnackbar(message, { ...originalOptions, content })
        }
    }, [enqueueSnackbar]);


    return {
        enqueueSnackbar: makeSnackbar,
        closeSnackbar
    };
};

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useSnackbar;

