/* Options:
Date: 2021-07-07 09:58:47
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44379

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: IAuthSession,Authenticate.*,IHasSessionId,IHasBearerToken,ResponseStatus,ResponseError,IReturn`1,IGet,IPost,IPut,IDelete,NewtonGreen.ServiceStack.Forms.Interfaces/*,NewtonGreen.ServiceStack.Forms.Enumerations/*,__Forms.File/*,__Forms.Lookup/*,__Forms.FormDefinition/*,__Forms.FormType/*,__Forms.Lookup/*,FormMetadata,PostUploadFile,GetUpload,DownloadUpload,GetUploadInfo,DeleteUpload,UploadResponse,GetUser,GetUserResponse
//ExcludeTypes: 
DefaultImports: File:./file,Lookup:./lookup,FormDefinition:./formDefinition,FormType:./formType
*/

import { File } from "./file";
import { Lookup } from "./lookup";
import { FormDefinition } from "./formDefinition";
import { FormType } from "./formType";

export interface IReturn<T>
{
    createResponse(): T;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IUserGetSingle
{
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IHasCode
{
    code: string;
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form type.
    */
    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form types.
    */
    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/medical-review/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'PostUploadFile'; }
}

// @Route("/medical-review/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'GetUploadInfo'; }
}

// @Route("/medical-review/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetUpload'; }
}

// @Route("/medical-review/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadUpload'; }
}

// @Route("/medical-review/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'DeleteUpload'; }
}

/**
* Lookup
*/
// @Route("/medical-review/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupCollectionResponse(); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeCollectionResponse(); }
    public getTypeName() { return 'FormTypeGetCollection'; }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
}

