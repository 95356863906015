/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import { IFormProps as IFormBaseProps, IFormValidate, IFormAllowSubmit, IFormSubmitValidationFailed, IFormSubmitFailed, IFormSubmit} from '@ngt/forms-core';

import { IDataModel, IValidationError, IValidationResult, ValidationErrorType } from '../../api/dtos';

import Form, { IFormProps } from './Form';
import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';
import asyncDebounce from '../../utilities/asyncDebounce';
import titleCase from '../../utilities/titleCase';
import ISaveModel from '../../utilities/ISaveModel';
import IValidateModel from '../../utilities/IValidateModel';
import FormsContext from '../../contexts/FormsContext';
import { useCallback } from 'react';
import useSnackbar, { SnackbarVariant } from '../../hooks/useSnackbar';
import { AlertTitle } from '@material-ui/lab';
import pluralize from 'pluralize';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: React.ReactNode;
//}

type OmitCalculatedTypes<T> = Omit<T, ''>

/**
 * This interface defines the properties for the Form component.
 */
export interface IDataFormProps<TData extends IDataModel = IDataModel, TValidationResult extends IValidationResult = IValidationResult> extends OmitCalculatedTypes<IFormProps<TData>> {
    entity: string;
    metadata?: Record<string, any>;
    save?: ISaveModel<TData>;
    validate?: IValidateModel<TData, TValidationResult>;
    validateDebounce?: number | null;
    onAfterSubmit?: IFormSubmit<TData, IValidationError>;
}

const errorVariantMapping: Record<ValidationErrorType, SnackbarVariant> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Low]: 'error-low',
    [ValidationErrorType.Normal]: 'error-normal',
    [ValidationErrorType.High]: 'error-high',
    [ValidationErrorType.Critical]: 'error-critical'
}

const errorTextMapping: Record<ValidationErrorType, string> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Low]: 'low priority error',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.High]: 'high priority error',
    [ValidationErrorType.Critical]: 'critical error'
}
/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const DataForm = <TData extends IDataModel, TValidationResult extends IValidationResult = IValidationResult>({
    save,
    validate,
    onValidate,
    metadata,
    validateDebounce,
    allowSubmit,
    onSubmitValidationFailed,
    onSubmit,
    onAfterSubmit,
    onSubmitFailed,
    entity,
    ...formProps
}: IDataFormProps<TData, TValidationResult>) => {
    const forms = React.useContext(FormsContext);

    const { enqueueSnackbar } = useSnackbar();

    const handleValidate: IFormValidate<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onValidate) {
            onValidate(formState, formActions);
        }

        if (!validate) {
            return {};
        }

        const { values } = formState;
        const validationResult = await validate(values, metadata);
        
        // parse errors into a format the form understands.
        const groupErrors = validationResult?.errors?.reduce((a: Record<string, IValidationError[]>, b: IValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        // return errors;
        return groupErrors;
    }, [validate, metadata, onValidate]);



    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        if (validateDebounce !== null && validateDebounce !== undefined && validateDebounce <= 0) {
            return handleValidate
        }

        return asyncDebounce(handleValidate, validateDebounce ?? 500);
    }, [handleValidate, validateDebounce]);

    const allowSubmitToUse: IFormAllowSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (allowSubmit)
        {
            return await allowSubmit(formState, formActions);
        }

        const {errors} = formState;

        if (!errors) {
            return true;
        }

       return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => e.type !== ValidationErrorType.Warning));
    }, [allowSubmit]);

    

    const onSubmitValidationFailure: IFormSubmitValidationFailed<TData, IValidationError> = useCallback(async (formState, formActions, validationError: boolean) => {
        if (onSubmitValidationFailed) {
            return onSubmitValidationFailed(formState, formActions, validationError);
        }

        const entityTitleCase = titleCase(entity);
        const entityLowerCase = entity?.toLowerCase();

        const {errors} = formState;

        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate the {entityLowerCase}.
                </>,
                { variant: 'error-critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type !== ValidationErrorType.Warning) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the {entityLowerCase} again.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [enqueueSnackbar, entity, onSubmitValidationFailed]);

    const onFormSubmitFailure: IFormSubmitFailed<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onSubmitFailed) {
            return onSubmitFailed(formState, formActions);
        }

        const entityTitleCase = titleCase(entity);
        const entityLowerCase = entity?.toLowerCase();

        enqueueSnackbar(
            <>
                <AlertTitle>
                    {entityTitleCase} Not Saved
                    </AlertTitle>
                    An error occurred while attempting to save the {entityLowerCase}.
            </>,
            { variant: 'error-critical' }
        );
    }, [onSubmitFailed, entity]);

    

    const handleSubmit: IFormSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onSubmit) {
            return onSubmit(formState, formActions);
        }

        const { values, errors } = formState;
        const { submitType, ...form } = values as any;

        if (!save) {
            return {};
        }

        const entityTitleCase = titleCase(entity);
        const entityLowerCase = entity?.toLowerCase();

        const response = await save(values, metadata, false);

        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Saved
                    </AlertTitle>
                    The {entityLowerCase} was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                </>,
                { variant: errorVariantMapping[maxErrorType] }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Saved
                    </AlertTitle>
                    The {entityLowerCase} was successfully saved.
                </>,
                { variant: 'success' }
            );
        }

        if (onAfterSubmit) {
            return onAfterSubmit({ ...formState, values: response! }, formActions);
        }

    }, [onSubmit, forms, enqueueSnackbar, entity, metadata, onAfterSubmit])

    return (
        <Form
            {...formProps}
            onValidate={debouncedValidate}
            allowSubmit={allowSubmitToUse}
            onSubmit={handleSubmit}
            onSubmitValidationFailed={onSubmitValidationFailure}
            onSubmitFailed={onFormSubmitFailure}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default DataForm;