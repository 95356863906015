/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Paper } from '@material-ui/core';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import FormLookupsContext from '../../../contexts/form/FormLookupsContext';

import * as Dtos from '../../../api/dtos';

import CollapsibleTable from '../../table/CollapsibleTable';

import Table from '../../table/Table';
import { GridColDef } from '@material-ui/data-grid';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormValues component.
 */
export interface IFormLookupsProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const columns: GridColDef[] = [
    {
        field: 'propertyName',
        headerName: 'Property Path',
        flex: 1
    },
    {
        field: 'itemCount',
        headerName: 'Item Count',
        valueGetter: params => params.row.items?.length ?? 0,
        flex: 1
    }
]

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormLookups: React.FunctionComponent<IFormLookupsProps> = ({
    children
}) => {
    const lookups = React.useContext(FormLookupsContext);

    const data = React.useMemo(() => {
        return lookups?.map((d, i) => ({ id: i, ...d }));
    }, [lookups])


    return (
        <CollapsibleTable
            title="Lookups"
            loading={false}
            rows={data ?? []}
            columns={columns}
            startCollapsed
            autoHeight
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormLookups;